import styled from "styled-components";
import tw from "tailwind-styled-components";
import NextImage from "../next-image/next-image";

export const HorizontalCardListItemWrapper = tw.div`
  flex desktop:grid
  flex-col
  gap-6

  desktop:grid-cols-3
`;

export const Image = tw(NextImage)`
  min-h-0 desktop:min-h-[340px]
  h-40 desktop:h-full
  max-h-40 desktop:max-h-full
  object-cover
  rounded-2xl desktop:rounded-[32px]
`;

export const InformationWrapper = tw.div`
  col-span-2
  flex
  flex-col
  gap-6 desktop:gap-8

  p-5 desktop:p-0
  bg-gray-100 desktop:bg-transparent
  rounded-2xl desktop:rounded-none
`;

export const Title = tw.h3`
  text-2xl desktop:text-[34px]
  font-semibold desktop:font-normal
`;

export const BodyWrapper = styled.div`
  p {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (min-width: 1128px) {
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const LinkWrapper = tw.div`
  flex
  w-full desktop:w-fit
  justify-center
`;
